import { Link } from "gatsby"
import React from "react"

import { REACT_APP_SIGNUP_URL } from "../../constants"
import { CTA2 } from "../index/CTA"
import { ImageLeft, ImageRight } from "../parts/blocks"
import { SectionTitle } from "../parts/Title"

export function Values() {
  return (
    <>
      <Training />
      <CTA2 title="Không gian làm việc riêng biệt cho đội nhóm" />
      <Reporting />
    </>
  )
}

function Training() {
  const content = (
    <>
      <SectionTitle>Tiết kiệm nỗ lực đào tạo nhân viên</SectionTitle>

      <p className="mb-8 leading-relaxed text-gray-normal text-left">
        <span className="font-bold text-blue-night">cokhach.vn</span> tích hợp
        mọi tính năng sales cần để bắt đầu tìm kiếm và nuôi dưỡng khách hàng,
        không đòi hỏi kinh nghiệm & kỹ năng.
        <ul className="list-none list-inside pt-8 w-full text-left text-blue-night">
          <li>
            • 100+ mẫu landing page Bất động sản có tỷ lệ chuyển đổi tốt nhất.
          </li>
          <li>
            • Giao diện dễ sử dụng, bất kỳ ai cũng có thể thao tác nhanh chóng.
          </li>
          <li>
            • Hướng dẫn cụ thể, chi tiết từng bước, đơn giản hoá hoạt động
            marketing.
          </li>
        </ul>
      </p>
    </>
  )
  const image = (
    <img
      className="object-cover object-center rounded"
      alt="manager"
      src="https://cdn.cokhach.vn/pimage/home-page/mgn-right-image.png"
    />
  )
  return <ImageRight {...{ content, image }} />
}

function Reporting() {
  const content = (
    <>
      <SectionTitle>Quản lý & phát triển nhóm hiệu quả</SectionTitle>

      <p className="mb-8 leading-relaxed text-gray-normal text-left">
        Với cokhach.vn, bạn dễ dàng thấu hiểu đội nhóm, theo dõi năng suất, phát
        hiện vấn đề và xử lý kịp thời, phân phối nhân sự phù hợp.
        <ul className="list-none list-inside pt-8 w-full text-left text-blue-night">
          <li>
            • Báo cáo chi tiết về tình trạng và quá trình chăm sóc khách hàng.
          </li>
          <li>
            • Đa dạng tính năng hỗ trợ hoạt động nhóm: phân quyền dữ liệu, giao
            việc, mời hợp tác theo nhóm 2-3 người…
          </li>
          <li>
            • Quản lý quyền, nhiệm vụ và năng suất của từng thành viên nhóm.
          </li>
        </ul>
      </p>
      <Link
        id="managers_singup"
        className="md:mt-0 mt-8 md:w-auto w-full font-medium text-white bg-pink-medium border-0 py-md px-5 focus:outline-none hover:bg-pink-500 rounded-md text-base"
        to={`${REACT_APP_SIGNUP_URL}`}
        target="_blank"
      >
        Dùng thử miễn phí
      </Link>
    </>
  )
  const image = (
    <img
      className="object-cover object-center rounded"
      alt="manager"
      src="https://cdn.cokhach.vn/pimage/home-page/mgn-left-image.png"
    />
  )
  return <ImageLeft {...{ content, image }} />
}
