import React, { ReactElement } from 'react';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { CTA2 } from '../components/index/CTA';
import { Pricing } from '../components/index/Pricing';
import { Managers } from '../components/managers/Managers';
import { Meta } from '../components/Meta';

interface Props { }

function Features(_props: Props): ReactElement {
  return (
    <>
      <Meta title="Hỗ trợ đội nhóm sales bất động sản tăng tốc doanh số | cokhach.vn"
        description="Không gian làm việc chuyên nghiệp cho nhóm. Quản lý & phát triển nhóm hiệu quả, tiết kiệm nỗ lực đào tạo nhân sự. | Bắt đầu sử dụng miễn phí!" />
      <Header isBgTransparent={true} />
      <Managers />
      <Pricing />
      <CTA2 />
      <Footer />
    </>
  )
}

export default Features
